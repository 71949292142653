<template>
  <div id="content-area">
    <span class="copyMessage" v-if="copyMessage">Link kopieret til udklipsholder!</span>
    <h1>Inviter medarbejderne</h1>
    <section>
      <p class="align-left">
        Kopier linket nedenfor og send det til de medarbejdere, som skal deltage i målingen. De behøver ikke at logge ind for at svare på spørgeskemaet, og de kan ikke se målingens resultat og anbefalinger. Vær opmærksom på, at det kan være muligt at besvare spørgeskemaet flere gange.
      </p>
      <div class="input-container">
        <input
          ref="linkInput"
          type="text"
          readonly
          :value="`${baseUrl}/#/udfyld/${id}/sg/uden-scope`"
          @click="selectLink"
        >
        <copy-icon class="copy-icon" @click="clipboard(`${baseUrl}/#/udfyld/${id}/sg/uden-scope`)"></copy-icon>
      </div>
    </section>
    <section>
      <h2 class="align-left">Links til flere målinger</h2>
      <p class="align-left">I kan oprette flere links og på den måde gentage målingen og sammenligne resultatet over tid, eller sammenligne resultatet i forskellige afdelinger eller faggrupper. Under ‘Se resultat’ kan I se resultaterne, der er baseret på svar fra de forskellige links.</p>
      <div v-for="scope in scopes" :key="scope" style="width: 100%; text-align: left;">
        <h5>{{scope.title}} <button @click="deleteScope(scope.id)">slet</button></h5>
        <div class="input-container">
          <input
            ref="linkInput"
            type="text"
            readonly
            :value="`${baseUrl}/#/udfyld/${id}/sg/${scope.id}`"
            @click="selectLink"
          >
          <copy-icon class="copy-icon" @click="clipboard(`${baseUrl}/#/udfyld/${id}/sg/${scope.id}`)"></copy-icon>
        </div>
      </div>
      <base-ui-button @clicked="createLink">+ Ny måling</base-ui-button>
    </section>
  </div>
</template>

<script>
import BaseUiButton from '../components/shared/BaseUiButton.vue'
import CopyIcon from '../components/shared/icons/CopyIcon.vue'
import { copyToClipboard } from '../sharedFunctions/helpers'

export default {
  components: { BaseUiButton, CopyIcon },
  emits: ['update-background'],
  data () {
    return {
      copyMessage: false
    }
  },
  computed: {
    id () {
      return this.$store.state.survey.id
    },
    scopes () {
      return this.$store.state.survey.data ? this.$store.state.survey.data.customScopes : []
    },
    baseUrl () {
      return window.location.origin
    }
  },
  async mounted () {
    this.$emit('update-background', '#2a6889')
    await this.$store.dispatch('survey/initSurvey', { surveyId: null, loggedIn: true })
  },
  methods: {
    selectLink (e) {
      e.target.select()
    },
    clipboard (link) {
      copyToClipboard(link).then(() => {
        this.copyMessage = true
        window.setTimeout(() => {
          this.copyMessage = false
        }, 2500)
      })
    },
    createLink () {
      const name = prompt('Giv målingen et navn. F.eks. Team 1 eller Decembermåling')
      if (name) {
        this.$store.dispatch('survey/addScope', name)
      }
    },
    deleteScope (id) {
      const ok = confirm('Er du sikker på at du vil slette dette link. Dette sletter også de svar der evt. er indgivet via dette link')
      if (ok) {
        this.$store.dispatch('survey/deleteScope', id)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.copyMessage
  position fixed
  top 1rem
  left calc(50% - 100px)
  right calc(50% - 100px)
  width 200px
  padding .2rem
  font-size 13px
  color black
  background white

#content-area
  max-width 900px

.content
  flex-grow 1

.content input
  width 100%

.copy-icon:hover
  cursor pointer
  background-color rgba(255,255,255,.07)

.copy-icon:active
  cursor pointer
  background-color rgba(255,255,255,.12)

.scopes-header
  margin-top 2rem
  width 100%
  display flex
  flex-flow row
  justify-content space-between
  align-items center
  flex-wrap wrap

.scopes-header h3
  margin 0
</style>
